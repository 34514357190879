import { formatReactSingleSelectData } from '@/components/ui/select/selectStyle'
import { GroupBase } from 'react-select'
import { Product } from '../globalRedux/filter/productFilter'
import { SubscriptionType } from './subscriptionType'

export interface Language {
  id: number
  name: string
  locale: string
  checked?: boolean
}
export interface ListType {
  id: string
  name: string
}
export interface OptionsType {
  id: number
  name: string
}
export interface OriginResponseType extends OptionsType {
  origin_type: string
}
/**
 * Option type for the grouped MultiSelect dropdown.
 */
export interface SelectOptionType {
  id?: number
  label: string
  value: string
  other?: any
  name?: string
  __isNew__?: boolean
}

/**
 * Grouped options type
 */
export interface GroupedSelectOptionType extends GroupBase<SelectOptionType> {
  label: string
  options: SelectOptionType[]
}
interface FormatDataForGroupsType {
  label: string
  list: OptionsType[]
}

export const formatDataForGroups = ({
  label,
  list,
}: FormatDataForGroupsType): GroupedSelectOptionType => {
  return {
    label: label,
    options: formatReactSingleSelectData(list),
  }
}
export type OriginType = 'Country' | 'Region'
export const formatCountryData = ({
  id,
  name,
  origin_type,
}: {
  id: number
  name: string
  origin_type: OriginType
}) => {
  return {
    id: id,
    value: name,
    label: name,
    origin_type: origin_type,

    other: {
      id: id,
      value: name,
      label: name,
      origin_type: origin_type,
    },
  }
}
export interface ConstantDataState {
  loading: boolean
  languages: Language[]
  curatedLanguages: Language[]
  countriesList: CountryType[]
  countriesWithOutAllCountry: CountryType[]
  functionList: ListType[]
  productFamilyList: ListType[]
  productTypesList: ListType[]
  currencyList: ListType[]
  qualityList: ListType[]
  unitsList: ListType[]
  time_frequency: ListType[]
  incotermsList: ListType[]
  businessSectorList: ListType[]
  certificationsList: ListType[]
  productCertificationsList: ListType[]
  criteriaList: ListType[]
  activityList: ListType[]
  subscriptionsList: SubscriptionType[]
  error: string
  search_initial_load: boolean
}

export interface CountryType {
  name: string
  id: number
  dial_code?: string
  country_code: string
  emoji: string
  flag: string
  has_regions?: boolean
}
export interface CompanyList {
  id: number
  name: string
  friendly_url: string
  country: string
  city: { id: number; name: string }
}

export interface ButtonTextType {
  [key: string]: {
    [key: string]: string
  }
}

export interface ContinentType {
  id: number
  name: string
}

export interface ProductWithIndustryResponse {
  industries: ProductWithIndustry[]
  status: string
}

export interface ProductWithIndustry {
  id: number
  name: string
  products: Product[]
}

export interface CriteriaResponseType {
  criteria: ListType[]
  status: string
}

export type OrderType = 'asc' | 'desc'
export type NewsFilterOrderType = 'Publish Date' | 'Title'
export type CompanySortOptionType = 'Latest Company' | 'Company Name'
export interface SearchParams {
  [key: string]: string
}

export type LanguageType = 'English' | 'French' | 'Spanish' | 'Italian'

export type LocalType = 'en' | 'es' | 'it' | 'fr'

export const languages = ['English', 'French', 'Spanish', 'Italian']

export type StatusType = 'success' | 'error' | 'failure'
