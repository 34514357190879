export const addValuesOrRemoveValues = <T>(
  value: T,
  existingValues: T[]
): T[] => {
  if (!existingValues.includes(value)) {
    return [value, ...existingValues]
  }
  return existingValues.filter((item) => item !== value)
}

export const addOrRemoveParticularGroup = <T>(
  selectingValues: T[],
  containerArray: T[]
): T[] => {
  if (
    containerArray?.length > 0 &&
    containerArray.every((item) => selectingValues.includes(item))
  ) {
    return containerArray.filter((item) => !selectingValues.includes(item))
  } else {
    const uniqueItems = selectingValues.filter(
      (item) => !containerArray.includes(item)
    )
    return [...containerArray, ...uniqueItems]
  }
}

export const addOrRemoveById = (value: any, existingValues: any) => {
  if (existingValues?.find((item: any) => item?.id == value.id)) {
    return existingValues?.filter((item: any) => item?.id !== value?.id)
  }
  return [...existingValues, value]
}
