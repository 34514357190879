export const languageTransition = [
  {
    path: 'en',
    language: 'English',
  },
  {
    path: 'it',
    language: 'Italian',
  },
  {
    path: 'fr',
    language: 'French',
  },
  {
    path: 'es',
    language: 'Spanish',
  },
]

export const languageMap: Record<any, string> = {
  en: 'English',
  it: 'Italian',
  fr: 'French',
  es: 'Spanish',
}
