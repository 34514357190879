import { Country } from '@/app/types/companySearchType'
import {
  ContinentType,
  LanguageType,
  NewsFilterOrderType,
  OrderType,
} from '@/app/types/constantDataType'
import { addOrRemoveById, addValuesOrRemoveValues } from '@/lib/filterUtils'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RetailNewsFilterType {
  retailNewsCriterias: string[]
  retailNewsFilterSubDivision: string[]
  retailNewsFilterCountry: Country[]
  retailNewsFilterContienent: ContinentType[]
  retailNewsFilterLanguages: LanguageType
  retailNewsFilterSortOption: NewsFilterOrderType
  retailNewsFilterOrder: OrderType
}

export const newsFilterInitalState: RetailNewsFilterType = {
  retailNewsCriterias: [],
  retailNewsFilterSubDivision: [],
  retailNewsFilterCountry: [],
  retailNewsFilterContienent: [],
  retailNewsFilterLanguages: 'English',
  retailNewsFilterSortOption: 'Publish Date',
  retailNewsFilterOrder: 'desc',
}

export const retailNewsFilter = createSlice({
  name: 'retailNewsFilter',
  initialState: newsFilterInitalState,
  reducers: {
    setRetailNewsFilterSortOption: (
      state,
      action: PayloadAction<NewsFilterOrderType>
    ) => {
      return {
        ...state,
        retailNewsFilterSortOption: action.payload,
      }
    },

    setRetailNewsFilterOrder: (state, action: PayloadAction<OrderType>) => {
      return {
        ...state,
        retailNewsFilterOrder: action.payload,
      }
    },
    addOrRemoveRetailNEwsFilterSubDivision: (
      state,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        retailNewsFilterSubDivision: addValuesOrRemoveValues(
          action.payload,
          state.retailNewsFilterSubDivision
        ),
      }
    },
    addOrRemoveRetailNewsFilterCriteria: (
      state,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        retailNewsCriterias: addValuesOrRemoveValues<string>(
          action.payload,
          state.retailNewsCriterias
        ),
      }
    },
    addOrRemoveRetailNewsFilterCountry: (
      state,
      action: PayloadAction<Country>
    ) => {
      const copyOfRetailNewsCountry = [...state.retailNewsFilterCountry]
      return {
        ...state,
        retailNewsFilterCountry: addOrRemoveById(
          action?.payload,
          copyOfRetailNewsCountry
        ),
      }
    },
    addOrRemoveRetailNewsFilterContinents: (
      state,
      action: PayloadAction<ContinentType>
    ) => {
      const copyOfRetailNewsContinents = [...state.retailNewsFilterContienent]

      return {
        ...state,
        retailNewsFilterContienent: addOrRemoveById(
          action.payload,
          copyOfRetailNewsContinents
        ),
      }
    },

    setRetailNewsFilterCountry: (state, action: PayloadAction<Country[]>) => {
      return {
        ...state,
        retailNewsFilterCountry: action.payload,
      }
    },

    setRetailNewsFilerLanguages: (
      state,
      action: PayloadAction<LanguageType>
    ) => {
      return {
        ...state,
        retailNewsFilterLanguages: action.payload,
      }
    },

    clearRetailNewsFilter: () => {
      return {
        ...newsFilterInitalState,
      }
    },
  },
})

export const {
  addOrRemoveRetailNewsFilterCountry,
  addOrRemoveRetailNewsFilterContinents,
  addOrRemoveRetailNewsFilterCriteria,
  setRetailNewsFilerLanguages,
  setRetailNewsFilterSortOption,
  setRetailNewsFilterOrder,
  setRetailNewsFilterCountry,
  addOrRemoveRetailNEwsFilterSubDivision,
  clearRetailNewsFilter,
} = retailNewsFilter?.actions

export default retailNewsFilter?.reducer
