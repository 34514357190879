import { Country } from '@/app/types/companySearchType'
import {
  CompanySortOptionType,
  ContinentType,
  OrderType,
} from '@/app/types/constantDataType'
import {
  addOrRemoveById,
  addOrRemoveParticularGroup,
  addValuesOrRemoveValues,
} from '@/lib/filterUtils'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { companyFvStaticBuyer, companyFvStaticSupplier } from '../companyFilter'

export interface RetailCompanyFilterType {
  retailSelectedActivity: string[]
  retailCompanyFilterCountry: Country[]
  retailCompanyFilterContienent: ContinentType[]
  retailCompanyFilterOrder: OrderType
  retailCompanySelectedSortOption: CompanySortOptionType
}

export const retailCompanyInitialState: RetailCompanyFilterType = {
  retailSelectedActivity: [],
  retailCompanyFilterCountry: [],
  retailCompanyFilterContienent: [],
  retailCompanyFilterOrder: 'desc',
  retailCompanySelectedSortOption: 'Latest Company',
}

export const retailCompanyFilterSlice = createSlice({
  name: 'retailCompanyFilter',
  initialState: retailCompanyInitialState,
  reducers: {
    setRetailCompanyOrder: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        retailCompanyFilterOrder: action.payload as OrderType,
      }
    },
    setRetailCompanySort: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        retailCompanySelectedSortOption:
          action.payload as CompanySortOptionType,
      }
    },
    addOrRemoveRetailCompanySelectedActivity: (
      state,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        retailSelectedActivity: addValuesOrRemoveValues<string>(
          action.payload,
          state.retailSelectedActivity
        ),
      }
    },
    setSelectAllRetailCompanySuppliers: (state) => {
      const retailSelectedActivityCopy = [...state.retailSelectedActivity]

      return {
        ...state,
        retailSelectedActivity: addOrRemoveParticularGroup<string>(
          companyFvStaticSupplier,
          retailSelectedActivityCopy
        ),
      }
    },
    setSelectAllRetailCompanyBuyers: (state) => {
      const retailSelectedActivityCopy = [...state.retailSelectedActivity]

      return {
        ...state,
        retailSelectedActivity: addOrRemoveParticularGroup<string>(
          companyFvStaticBuyer,
          retailSelectedActivityCopy
        ),
      }
    },
    addOrRemoveRetailCompanyCountry: (
      state,
      action: PayloadAction<Country>
    ) => {
      const retailSelectedCountryCopy = [...state.retailCompanyFilterCountry]
      return {
        ...state,
        retailCompanyFilterCountry: addOrRemoveById(
          action.payload,
          retailSelectedCountryCopy
        ),
      }
    },
    addOrRemoveRetailCompanyContinent: (
      state,
      action: PayloadAction<ContinentType>
    ) => {
      const retailSelectedContinentCopy = [
        ...state.retailCompanyFilterContienent,
      ]
      return {
        ...state,
        retailCompanyFilterContienent: addOrRemoveById(
          action.payload,
          retailSelectedContinentCopy
        ),
      }
    },

    setRetailCompanyCountryFilter: (
      state,
      action: PayloadAction<Country[]>
    ) => {
      return {
        ...state,
        retailCompanyFilterCountry: action.payload,
      }
    },

    clearRetailCompaniesFilter: () => {
      return {
        ...retailCompanyInitialState,
      }
    },
  },
})

export const {
  addOrRemoveRetailCompanySelectedActivity,
  setSelectAllRetailCompanySuppliers,
  setSelectAllRetailCompanyBuyers,
  setRetailCompanyCountryFilter,
  addOrRemoveRetailCompanyCountry,
  addOrRemoveRetailCompanyContinent,
  setRetailCompanyOrder,
  setRetailCompanySort,
  clearRetailCompaniesFilter,
} = retailCompanyFilterSlice?.actions
export default retailCompanyFilterSlice?.reducer
